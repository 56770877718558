/*
Author: Eli Elad Elrom
File: clientDashboardSelectors.ts
*/

import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { HttpMethod } from 'enums/httpMethodEnum';
import { IDeletedResponse } from 'interfaces';
import { getURLByEndpoint } from './SelectorsHelper';
import { ClientDashboardBookmarkModel } from 'models/clientDashboardBookmark';
import { CreateClientDashboardBookmarkModelDto } from 'types/client-dashboard-bookmark';
import { request } from '../../../pages/api/fetchService';
import { userModelState } from 'recoil/atoms/userAtoms';
import { v4 as uuid } from 'uuid';
import { API_PATH } from 'components/routing/constants/api';

export const createClientDashboardBookmarkModelRequest = async (
  dto: CreateClientDashboardBookmarkModelDto
): Promise<ClientDashboardBookmarkModel> => {
  return request<ClientDashboardBookmarkModel>(
    getURLByEndpoint(API_PATH.clientDashboardBookmark.createOne),
    HttpMethod.POST,
    dto
  );
};

export const deleteClientDashboardBookmarkModelRequest = async (
  clientDashboardUuid: string
): Promise<IDeletedResponse<ClientDashboardBookmarkModel>> => {
  return request<IDeletedResponse<ClientDashboardBookmarkModel>>(
    getURLByEndpoint(
      API_PATH.clientDashboardBookmark.deleteOne,
      clientDashboardUuid,
      'delete.json'
    ),
    HttpMethod.DELETE
  );
};

export const createClientDashboardBookmarkModelSelector = selector({
  key: 'CreateClientDashboardBookmarkModelSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);

    return getCallback(() => async (clientDashboardUuid: string) => {
      const dto = {
        clientDashboardBookmarkUuid: uuid(),
        clientDashboardUuid,
        userUuid: userModel.userUuid,
      };

      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: prevUserModel.clientDashboardModels.map((cd) =>
          cd.clientDashboardUuid === clientDashboardUuid ? { ...cd, bookmarked: true } : cd
        ),
        clientDashboardBookmarkModels: [...prevUserModel.clientDashboardBookmarkModels, dto],
      }));

      await createClientDashboardBookmarkModelRequest(dto);
    });
  },
});

export const deleteClientDashboardBookmarkModelSelector = selector({
  key: 'DeleteClientDashboardBookmarkModelSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (clientDashboardUuid: string) => {
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: prevUserModel.clientDashboardModels.map((cd) =>
          cd.clientDashboardUuid === clientDashboardUuid ? { ...cd, bookmarked: false } : cd
        ),
        clientDashboardBookmarkModels: prevUserModel.clientDashboardBookmarkModels.filter(
          (cdb) => cdb.clientDashboardUuid !== clientDashboardUuid
        ),
      }));

      await deleteClientDashboardBookmarkModelRequest(clientDashboardUuid);
    });
  },
});
