import { GridViewControllerProps } from 'components/ui/GridViewController/GridViewController';
import React, { useMemo } from 'react';
import DashboardGalleryHeader from '../../layout/DashboardGalleryHeader/DashboardGalleryHeader';
import DashboardGallery from '../DashboardGallery/DashboardGallery';
import { Wrapper } from './HomePageContent.css';
import useClientDashboardsSortingAndFiltering from './useDashboardFiltering';
import { useRecoilValue } from 'recoil';
import {
  createClientDashboardBookmarkModelSelector,
  deleteClientDashboardBookmarkModelSelector,
} from 'recoil/selectors/clientDashboardBookmarkSelectors';
import { userModelState } from 'recoil/atoms/userAtoms';

export const HomePageContent = ({ dashboardsViewType, onGridViewChange }: HomePageContentProps) => {
  const userModel = useRecoilValue(userModelState);
  const createClientDashboardBookmarkModel = useRecoilValue(
    createClientDashboardBookmarkModelSelector
  );
  const deleteClientDashboardBookmarkModel = useRecoilValue(
    deleteClientDashboardBookmarkModelSelector
  );

  const dashboardCategories = useMemo(() => {
    const uniqueCategories = Array.from(
      new Set(userModel.clientDashboardModels.map((model) => model.dashboardCategory))
    );
    return uniqueCategories;
  }, [userModel.clientDashboardModels]);

  const onBookmarkClickHandler = async (clientDashboardUuid: string, isBookmarked: boolean) => {
    if (isBookmarked) {
      await createClientDashboardBookmarkModel(clientDashboardUuid);
    } else {
      await deleteClientDashboardBookmarkModel(clientDashboardUuid);
    }
  };

  const {
    onClientDashboardFilterChange,
    onClientDashboardSearchChange,
    onClearSearchQuery,
    filteredClientDashboardModels,
  } = useClientDashboardsSortingAndFiltering(userModel.clientDashboardModels);

  return (
    <Wrapper>
      <DashboardGalleryHeader
        dashboardsViewType={dashboardsViewType}
        onGridViewChange={onGridViewChange}
        dashboardFilters={[...dashboardCategories]}
        onDashboardFilterChange={onClientDashboardFilterChange}
        onSearchQueryChange={onClientDashboardSearchChange}
        onClearSearchQuery={onClearSearchQuery}
      />
      <DashboardGallery
        dashboardsViewType={dashboardsViewType}
        clientDashboardModels={filteredClientDashboardModels}
        onBookmarkClick={onBookmarkClickHandler}
        onClientDashboardClick={() => {}}
      />
    </Wrapper>
  );
};

export type HomePageContentProps = GridViewControllerProps;
