import styled from '@emotion/styled';
import { SITE_HEADER_HEIGHT } from '../../../utils/layoutHelper';

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: ${SITE_HEADER_HEIGHT};
  background: ${(props) => props.theme.palette.white.main};
  height: ${SITE_HEADER_HEIGHT};
  z-index: 1;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 0 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(85, 73, 158, 0.08) 0%, rgba(255, 255, 255, 0) 30%);
`;

export const Right = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  height: 100%;
`;

export const Divider = styled.div`
  height: 50%;
  margin: 0 1.5rem;
  width: 0;
  border-left: 1px solid ${(props) => props.theme.palette.cloudGrey.main};
`;

export const SearchBarWrapper = styled.div`
  margin: 0 2.6rem;
  min-width: 30rem;
`;
