import styled from 'styled-components';
import { DashboardsViewTypeEnum } from 'components/ui/GridViewController/GridViewController';
import { TransitionGroup } from 'react-transition-group';

export const Wrapper = styled.div<{ dashboardsViewType: DashboardsViewTypeEnum }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;

  & > div {
    width: 100vw;
    text-align: center;
  }

  ${(props) =>
    props.dashboardsViewType === DashboardsViewTypeEnum.LIST &&
    `
    flex-direction: column;
    align-items: center;
  `}

  .item-enter {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

export const TransitionGroupStyled = styled(TransitionGroup)`
  width: 100vw;
  text-align: center;
`;
