export interface ISorter<T> {
  property: Extract<keyof T, string | number | Date>;
  isDescending: boolean;
}
export function sortObjectsByProperty<T>(items: T[], sorters: ISorter<T>[]) {
  return [...items].sort((a, b) => {
    for (let sorter of sorters) {
      const propertyA = a[sorter.property];
      const propertyB = b[sorter.property];

      let comparison = 0;

      if (propertyA > propertyB) {
        comparison = 1;
      } else if (propertyA < propertyB) {
        comparison = -1;
      }

      if (comparison !== 0) {
        return sorter.isDescending ? comparison * -1 : comparison;
      }
    }
    return 0;
  });
}
