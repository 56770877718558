import styled from '@emotion/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BookmarksOutlined } from '@mui/icons-material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  gap: 1rem;
`;

export const StyledToggleButton = styled(ToggleButton)`
  &.MuiButtonBase-root {
    text-transform: none;
  }
  &.MuiToggleButton-root {
    border: 0;
  }
  // resetting default mui BR setup in 4 props based on element index
  &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: ${(props) => props.theme.shape.borderRadius}px;
    border-bottom-right-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-top-left-radius: ${(props) => props.theme.shape.borderRadius}px;
    border-bottom-left-radius: ${(props) => props.theme.shape.borderRadius}px;
  }
`;

export const StyledBookmarksOutlined = styled(BookmarksOutlined)`
  margin-right: 0.5rem;
`;

export const SelectWrapper = styled.div`
  width: 23rem;
`;
