import { DashboardsViewTypeEnum } from 'components/ui/GridViewController/GridViewController';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ClientDashboardModel } from '../../../models';
import DashboardGalleryCard from '../DashboardGalleryCard/DashboardGalleryCard';
import { TransitionGroupStyled, Wrapper } from './DashboardGallery.css';

const DashboardGallery = ({
  dashboardsViewType,
  clientDashboardModels,
  onBookmarkClick,
  onClientDashboardClick,
}: DashboardGalleryProps) => {
  return (
    <Wrapper dashboardsViewType={dashboardsViewType}>
      <TransitionGroupStyled id="dashboard-gallery-card-list">
        {clientDashboardModels.map((clientDashboardModel) => (
          <CSSTransition
            key={clientDashboardModel?.clientDashboardUuid}
            timeout={500}
            classNames="item"
          >
            <DashboardGalleryCard
              key={`dashboard-gallery-${clientDashboardModel?.clientDashboardUuid}`}
              dashboardsViewType={dashboardsViewType}
              clientDashboardModel={clientDashboardModel}
              onBookmarkClick={onBookmarkClick}
              onClientDashboardClick={onClientDashboardClick}
            />
          </CSSTransition>
        ))}
      </TransitionGroupStyled>
    </Wrapper>
  );
};

export interface DashboardGalleryProps {
  clientDashboardModels: ClientDashboardModel[];
  dashboardsViewType: DashboardsViewTypeEnum;
  onClientDashboardClick: (dashboardUuid: string) => void;
  onBookmarkClick: (clientDashboardUuid: string, isBookmarked: boolean) => void;
}

export default DashboardGallery;
