import { Content, Divider, Right, SearchBarWrapper, Wrapper } from './DashboardGalleryHeader.css';
import GridViewController, {
  GridViewControllerProps,
} from '../../ui/GridViewController/GridViewController';
import { GenericSearchBar, GenericSearchBarProps } from '@ay/pascl';
import DashboardGalleryFilters, {
  DashboardGalleryFiltersProps,
} from '../../ui/DashboardGalleryFilters/DashboardGalleryFilters';

const DashboardGalleryHeader = ({
  dashboardsViewType,
  onGridViewChange,
  dashboardFilters,
  onDashboardFilterChange,
  onSearchQueryChange,
  onClearSearchQuery,
}: DashboardGalleryHeaderProps) => (
  <Wrapper>
    <Content>
      <SearchBarWrapper>
        <GenericSearchBar
          onSearchQueryChange={onSearchQueryChange}
          onClearSearchQuery={onClearSearchQuery}
          size="small"
        />
      </SearchBarWrapper>
      <Right>
        <DashboardGalleryFilters
          dashboardFilters={dashboardFilters}
          onDashboardFilterChange={onDashboardFilterChange}
        />
        <Divider />
        <GridViewController
          dashboardsViewType={dashboardsViewType}
          onGridViewChange={onGridViewChange}
        />
      </Right>
    </Content>
  </Wrapper>
);

export type DashboardGalleryHeaderProps = DashboardGalleryFiltersProps &
  GridViewControllerProps &
  GenericSearchBarProps;

export default DashboardGalleryHeader;
