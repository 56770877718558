import styled from '@emotion/styled';
import { Card, Typography } from '@mui/material';

import { Checkbox } from '@ay/pascl';
import { DashboardsViewTypeEnum } from 'components/ui/GridViewController/GridViewController';
import { APP_CONFIG } from '../../../constants/config';

export const StyledCard = styled(Card)<{ dashboardsViewType: DashboardsViewTypeEnum }>`
  display: inline-flex;
  margin: 0em 1em 2em 1em;
  ${(props) =>
    props.dashboardsViewType === DashboardsViewTypeEnum.GRID
      ? `
    width: 35.5rem;
    height: 23rem;
    flex-direction: column;
  `
      : `
        display: flex;
        margin: 0em auto 2em auto;
        width: 45rem;
        height: 4.9rem;
      `}
  cursor: pointer;
`;

export const DashboardPlaceholder = styled.div<{
  dashboardsViewType: DashboardsViewTypeEnum;
  imageUrl: string | null;
}>`
  background-color: linear-gradient(
    180deg,
    rgba(58, 68, 88, 0.1) 0%,
    rgba(85, 73, 158, 0.05) 0.01%,
    rgba(85, 73, 158, 0.5) 100%
  );
  ${({ imageUrl }) => {
    const urlExists = imageUrl && imageUrl !== '' && imageUrl !== null;
    return `
    background: url(${
      urlExists ? imageUrl : `${APP_CONFIG.basePath}/images/dashboards/default.svg`
    }), linear-gradient(
    180deg,
    rgba(58, 68, 88, 0.1) 0%,
    rgba(85, 73, 158, 0.05) 0.01%,
    rgba(85, 73, 158, 0.5) 100%
  );
    background-size: contain;
    background-repeat: no-repeat;
  `;
  }};
  height: 17.4rem;

  ${(props) =>
    props.dashboardsViewType === DashboardsViewTypeEnum.LIST &&
    `
      width: 10rem;
      height: 4.9rem;
  `}
`;

export const GalleryCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  flex: 1;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: -1rem;
`;

export const StyledTypography = styled(Typography)`
  cursor: auto;
`;
