/*
Author: Eli Elad Elrom
Component: HomePage
 */

import { DashboardsViewTypeEnum } from 'components/ui/GridViewController/GridViewController';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { NextPublicEnvironmentEnum } from 'enums/nextPublicEnvironmentEnum';
import { userModelState } from 'recoil/atoms/userAtoms';
import { FailureCodes } from '../SomethingWentWrongPage/constants';
import { HomePageContent } from 'components/ui/HomePageContent/HomePageContent';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import useSetUserData, {
  SET_USER_DATA_ERROR_MESSAGES,
} from 'components/routing/hooks/useSetUserData';
import useOktaAuth from 'components/routing/hooks/useOktaAuth';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';

const HomePage = () => {
  const { push, pathname } = useRouter();

  const setUserData = useSetUserData();
  const { findTokenAndReturnAccessToken } = useOktaAuth();

  const [userModel, setUserModel] = useRecoilState(userModelState);
  const selectedDashboardModel = userModel.selectedClientDashboardModel?.dashboardModel;
  const [dashboardsViewType, setDashboardsViewType] = useState<DashboardsViewTypeEnum>(
    DashboardsViewTypeEnum.GRID
  );

  useEffect(() => {
    if (selectedDashboardModel?.selectedMainWidget == 'webpage') {
      setUserModel((prevUserModel) => ({
        ...prevUserModel,
        selectedClientDashboardModel: {
          ...prevUserModel.selectedClientDashboardModel,
          dashboardModel: {
            ...prevUserModel.selectedClientDashboardModel.dashboardModel!,
            selectedMainWidget: '',
          },
        },
      }));
      push(selectedDashboardModel?.dashboardRefLink);
    }
  }, [selectedDashboardModel?.dashboardRefLink, selectedDashboardModel?.selectedMainWidget]);

  // Fail safe to get the user-info data in case data was flushed.
  useEffect(() => {
    const getUserInfo = async () => {
      const accessToken = await findTokenAndReturnAccessToken();
      await setUserData(accessToken, 'HomePage');
    };
    const isOktaUserId = userModel.oktaUserId !== '';
    if (!isOktaUserId) {
      getUserInfo().catch((error) =>
        Snackbar.error(
          `${
            SET_USER_DATA_ERROR_MESSAGES.NO_RESULTS_FROM_SET_USER_DATA
          }, Catch Error: ${JSON.stringify(error)}`
        )
      );
    }
  }, [findTokenAndReturnAccessToken, userModel, setUserData]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT !== NextPublicEnvironmentEnum.LOCAL_SKIP_OKTA &&
      userModel.isAuth
    ) {
      if (!userModel.userClientModels?.length) {
        push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_WITHOUT_CLIENTS));
        return;
      }
      if (!userModel.clientDashboardModels.length) {
        push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_WITHOUT_DASHBOARDS));
        return;
      }
    }
  }, [pathname, userModel.userClientModels, userModel.clientDashboardModels, userModel.isAuth]);

  const onGridViewChangeHandler = (type: DashboardsViewTypeEnum) => {
    setDashboardsViewType(type);
  };

  return (
    <>
      {selectedDashboardModel?.selectedMainWidget !== 'webpage' && (
        <HomePageContent
          dashboardsViewType={dashboardsViewType}
          onGridViewChange={onGridViewChangeHandler}
        />
      )}
    </>
  );
};

export default HomePage;
