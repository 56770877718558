import { useSetRecoilState } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import { getUserRequest } from 'recoil/selectors/usersSelectors';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { formatClientDashboardsWithBookmarks } from 'utils/cliendDashboardHelper';
import logger from 'utils/logger';
import { getUserClientByActiveClientUuid } from 'utils/userHelper';

export const SET_USER_DATA_ERROR_MESSAGES = {
  NO_RESULTS_FROM_SET_USER_DATA:
    'Unable with request - back-end user and token problem, corrupt get-user-info data or incorrect internal coding error',
  TOKEN_NOT_PROVIDED: 'Token must be provided.',
};

const useSetUserData = () => {
  const setUserModel = useSetRecoilState(userModelState);

  const setUserData = async (accessToken: string, initiator: string) => {
    try {
      if (!accessToken) {
        Snackbar.error(
          `${SET_USER_DATA_ERROR_MESSAGES.TOKEN_NOT_PROVIDED}, accessToken: ${JSON.stringify(
            accessToken
          )}, Initiator: ${initiator}`
        );
        return;
      }
      logger(
        `useSetUserData :: getUserRequest - Initiator: ${initiator}, setUserData : ${accessToken}`
      );
      const results = await getUserRequest(accessToken);
      if (results) {
        const { userModel: resUser, clientDashboardModels: clientDashboardsRes } = results;

        const clientDashboardModels = formatClientDashboardsWithBookmarks(
          clientDashboardsRes,
          resUser.clientDashboardBookmarkModels || []
        );

        setUserModel((prevUserModel) => ({
          ...prevUserModel,
          ...resUser,
          accessToken,
          oktaClientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
          selectedUserClientModel: getUserClientByActiveClientUuid(
            resUser?.userClientModels || [],
            resUser.activeClientUuid
          ),
          isAuth: true,
          clientDashboardModels,
        }));
        return true;
      }
    } catch (error) {
      Snackbar.error(
        `${SET_USER_DATA_ERROR_MESSAGES.NO_RESULTS_FROM_SET_USER_DATA}, Error: ${JSON.stringify(
          error
        )}`
      );
      return false;
    }
  };

  return setUserData;
};

export default useSetUserData;
