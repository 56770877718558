import { useState } from 'react';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
  StyledBookmarksOutlined,
  SelectWrapper,
} from './DashboardGalleryFilters.css';
import { Typography } from '@mui/material';
import { FILTER_CONSTANTS } from '../HomePageContent/constants';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { BasicSelect } from '@ay/pascl';

const DashboardGalleryFilters = ({
  dashboardFilters,
  onDashboardFilterChange,
}: DashboardGalleryFiltersProps) => {
  const [filter, setFilter] = useState<string>(FILTER_CONSTANTS.SHOW_ALL);
  const handleChange = (_: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== null) {
      setFilter(newFilter);
      onDashboardFilterChange(newFilter);
    }
  };

  const filters = () => {
    const initFiltersCopy = [...dashboardFilters];
    initFiltersCopy.unshift(FILTER_CONSTANTS.SHOW_ALL);
    initFiltersCopy.push(FILTER_CONSTANTS.BOOKMARKED);
    return initFiltersCopy;
  };

  const selectOptions = filters().map((f) => ({ value: f, label: f }));
  const selectProps = {
    fullWidth: true,
    label: 'Filter By',
    labelId: 'dashboard-gallery-filter',
    options: selectOptions,
    onSelectChange: (newValue: string) => {
      setFilter(newValue);
      onDashboardFilterChange(newValue);
    },
  };

  return useIsMobile() ? (
    <SelectWrapper>
      <BasicSelect {...selectProps} value={filter} />
    </SelectWrapper>
  ) : (
    <StyledToggleButtonGroup value={filter} exclusive onChange={handleChange}>
      {filters().map((f) => (
        <StyledToggleButton
          key={`dashboard-gallery-filters-${f}`}
          value={f}
          color="primary"
          size="small"
        >
          {f === 'Bookmarked' && <StyledBookmarksOutlined fontSize="small" />}
          <Typography variant="widgetTitle">{f}</Typography>
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export interface DashboardGalleryFiltersProps {
  dashboardFilters: string[];
  onDashboardFilterChange: (newFilter: string) => void;
}

export default DashboardGalleryFilters;
