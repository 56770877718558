import { useMemo, useState } from 'react';
import { sortObjectsByProperty } from 'utils/sortObjectsByProperty';
import { CLIENT_DASHBOARD_SORTING_VALUES_ORDER } from '../../../constants/client-dashboards';
import { ClientDashboardModel } from '../../../models';
import { FILTER_CONSTANTS } from './constants';

export default function useClientDashboardsSortingAndFiltering(
  clientDashboardModels: ClientDashboardModel[]
) {
  const [filters, setFilters] = useState<{ category: string; query: string }>({
    category: FILTER_CONSTANTS.SHOW_ALL,
    query: '',
  });

  const filteredClientDashboardModels = useMemo(() => {
    let clientDashboardModelsCopy = [...clientDashboardModels];

    switch (filters.category) {
      case FILTER_CONSTANTS.BOOKMARKED:
        clientDashboardModelsCopy = clientDashboardModelsCopy.filter(
          (clientDashboardModel) => clientDashboardModel.bookmarked
        );
        break;
      case FILTER_CONSTANTS.SHOW_ALL:
        break;
      default:
        clientDashboardModelsCopy = clientDashboardModelsCopy.filter((clientDashboardModel) =>
          clientDashboardModel.dashboardCategory.includes(filters.category)
        );
        break;
    }

    const filterClientDashboards = () => {
      const query = filters.query.toLowerCase();
      return clientDashboardModelsCopy.filter(
        (clientDashboardModel) =>
          clientDashboardModel.dashboardName.toLowerCase().includes(query) ||
          clientDashboardModel.dashboardDescrip.toLowerCase().includes(query) ||
          clientDashboardModel.dashboardCategory.toLowerCase().includes(query)
      );
    };

    const searchQueryEmpty = filters.query === '';

    const filteredClientDashboardModelsByQuery = searchQueryEmpty
      ? clientDashboardModelsCopy
      : filterClientDashboards();

    return sortObjectsByProperty(
      filteredClientDashboardModelsByQuery,
      CLIENT_DASHBOARD_SORTING_VALUES_ORDER
    );
  }, [clientDashboardModels, filters]);

  const onClientDashboardFilterChange = (newFilter: string) =>
    setFilters({ ...filters, category: newFilter });
  const onClientDashboardSearchChange = (newValue: string) =>
    setFilters({ ...filters, query: newValue });
  const onClearSearchQuery = () => setFilters({ ...filters, query: '' });

  return {
    onClientDashboardFilterChange,
    onClientDashboardSearchChange,
    onClearSearchQuery,
    filters,
    filteredClientDashboardModels,
  };
}
